<template>
  <modal
    name="common-modal"
    overlayTransition=""
    width="100%"
    class="Modal CommonModal"
    @before-open="beforeOpen">
    <span
      class="ModalClose"
      @click="closeModal">
      ✕
    </span>
    <h3
      v-if="title"
      class="ModalTitle"
      v-html="title"/>
    <h4
      v-if="message"
      class="ModalMessage"
      v-html="message"/>
    <div class="ModalActions">
      <div
        class="CommonModalConfirm Button Button--primary"
        @click="onConfirm">
        {{ confirmLabel }}
      </div>
      <div
        v-if="cancelLabel"
        class="CommonModalCancel Button Button--ghost"
        @click="onCancel">
        {{ cancelLabel }}
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'common-modal',
  data() {
    return {
      title: null,
      message: null,
      confirmLabel: null,
      cancelLabel: null,
      confirmCallback: null,
      cancelCallback: null
    }
  },
  methods: {
    beforeOpen(event) {
      this.title = event.params.title
      this.message = event.params.message
      this.confirmLabel = event.params.confirmLabel || this.$t('commonModal.defaultConfirmLabel')
      this.cancelLabel = event.params.cancelLabel || (event.params.cancelCallback ? this.$t('commonModal.defaultCancelLabel') : null)
      this.confirmCallback = event.params.confirmCallback
      this.cancelCallback = event.params.cancelCallback
    },
    closeModal() {
      this.$modal.hide('common-modal')
    },
    onConfirm() {
      if (this.confirmCallback) {
        this.confirmCallback()
      }
      this.closeModal()
    },
    onCancel() {
      if (this.cancelCallback) {
        this.cancelCallback()
      }
      this.closeModal()
    }
  }
}
</script>
